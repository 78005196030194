import React, { useEffect, useMemo, useState } from "react";
import { createStyles, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Debug from "debug";
const debug = Debug("SS:Passes:Dispaly");
import { Grid, Typography, Button, Tooltip } from "@mui/material/";
import formatExpiration from "../../../utils/format_expiration";
import useMaxViewers from "../Hooks/use_max_viewers";

export default function DisplayActivePasses(props) {
  // State
  const [passViewers, setPassViewers] = useState(0);
  const [subViewers, setSubViewers] = useState(0);
  const [accountViewers, setAccountViewers] = useState(0);
  const [accountAvailableViewers, setAccountAvailableViewers] = useState(0);
  const [onlineViewers, setOnlineViewers] = useState(0);
  const [onlineAccountViewers, setOnlineAccountViewers] = useState(0);

  // Effects
  // Update Access totals
  useEffect(() => {
    let passUserCount = 0;
    let subUserCount = 0;
    let accountUserCount = 0;

    // Passes
    if (props.activePasses && props.activePasses.length > 0) {
      props.activePasses.forEach((pass, i) => {
        passUserCount += pass.max_allowed_users;
      });
    }
    // Subscriptions
    if (props.activeSubs && props.activeSubs.length > 0) {
      props.activeSubs.forEach((sub, i) => {
        if (sub.session_id_attached_to === "account") {
          accountUserCount += sub.max_allowed_users;
        } else {
          subUserCount += sub.max_allowed_users;
        }
      });
    }

    setPassViewers(passUserCount);
    setSubViewers(subUserCount);
    setAccountViewers(accountUserCount);
    debug("Online Viewers", passUserCount, subUserCount, accountUserCount);
  }, [props.activePasses, props.activeSubs]);

  // Update account total viewers
  useEffect(() => {
    let accountAvailableUsers = 0;

    if (props.availableSubs && props.availableSubs.length > 0) {
      props.availableSubs.forEach((sub) => {
        if (sub.session_id_attached_to === "account") {
          accountAvailableUsers += sub.max_allowed_users;
        }
      });
    }
    debug("Account Available Viewers", accountAvailableUsers);
    setAccountAvailableViewers(accountAvailableUsers);
  }, [props.availableSubs]);

  // Update online viewer count
  useEffect(() => {
    if (props.presenceRoom) {
      const handleMemberUpdate = () => {
        let memberCount = 0;
        props.presenceRoom.members?.each((member) => {
          if (
            member.info?.viewerType === "email" ||
            member.info?.viewerType === "web"
          ) {
            memberCount++;
          }
        });
        debug(
          "New Online Viewer Count",
          memberCount,
          props.presenceRoom.members
        );
        setOnlineViewers(memberCount);
      };

      if (props.presenceRoom) {
        // This will set the initial online viewer count
        props.presenceRoom.bind(
          "pusher:subscription_succeeded",
          handleMemberUpdate
        );

        // These will update the viewer count over time
        props.presenceRoom.bind("pusher:member_added", handleMemberUpdate);
        props.presenceRoom.bind("pusher:member_removed", handleMemberUpdate);

        return () => {
          props.presenceRoom?.unbind(
            "pusher:subscription_succeeded",
            handleMemberUpdate
          );
          props.presenceRoom?.unbind("pusher:member_added", handleMemberUpdate);
          props.presenceRoom?.unbind(
            "pusher:member_removed",
            handleMemberUpdate
          );
        };
      }
    }
  }, [props.presenceRoom, props.channel]);

  // Update onlineAccountViewers
  useEffect(() => {
    const accountViewersOnline = onlineViewers - passViewers - subViewers;
    debug("Account Online Viewers", accountViewersOnline);
    if (accountViewersOnline > 0) {
      setOnlineAccountViewers(accountViewersOnline);
    } else {
      setOnlineAccountViewers(0);
    }
  }, [onlineViewers, passViewers, subViewers]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6} className={props.cssClasses.tableCell}>
            {/* No active pass */}
            {props.activeSubs.length === 0 &&
              props.activePasses.length === 0 && (
                <Typography
                  variant="subtitle1"
                  className={props.cssClasses.alertText}
                >
                  NO ACTIVE PASS
                </Typography>
              )}

            {/* Has Active Pass */}
            {(props.activeSubs.length > 0 || props.activePasses.length > 0) && (
              <Typography variant="h6" className={props.cssClasses.alertText}>
                <Tooltip
                  title="Total number of viewers that can be online at the same time"
                  placement="right-end"
                >
                  {passViewers +
                    subViewers +
                    accountViewers +
                    onlineAccountViewers}{" "}
                  Viewers (
                </Tooltip>
                <Typography display="inline" color="primary">
                  <Tooltip
                    title="Number of viewers currently online"
                    placement="right-end"
                  >
                    {onlineViewers} online
                  </Tooltip>
                </Typography>
                )
              </Typography>
            )}

            <Grid
              container
              sx={{
                flexWrap: "nowrap",
              }}
            >
              <Grid
                xs={6}
                sx={{
                  minWidth: 112,
                }}
              >
                <Typography variant="subtitle2">
                  {passViewers} on Passes
                  {props.activePasses[0]
                    ? "Until " +
                      formatExpiration(props.activePasses[0].expires_on)
                    : null}
                </Typography>
                <Typography variant="subtitle2">
                  {subViewers} on Subscriptions
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography variant="subtitle2">
                  {accountViewers} / {accountAvailableViewers} on Account
                  <br /> Available
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} className={props.cssClasses.tableCell}>
            {/* No Active Pass */}
            {!props.isBroadcastOnly &&
              props.activeSubs.length === 0 &&
              props.activePasses.length === 0 && (
                <Button
                  onClick={() => props.handleOpen("activate")}
                  variant="contained"
                  color="primary"
                >
                  ACTIVATE PASS
                </Button>
              )}

            {/* Has Active Pass */}
            {!props.isBroadcastOnly &&
              (props.activeSubs.length > 0 ||
                props.activePasses.length > 0) && (
                <Button
                  onClick={() => props.handleOpen("activate")}
                  variant="contained"
                  color="secondary"
                >
                  MANAGE ACCESS
                </Button>
              )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
